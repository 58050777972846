import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import convertInputTime from '../utils/convertInputTime.js';

export default function WorkingHoursAndBreakDialog({ handleChangeOfWorkingHoursAndBreak }) {
  const [open, setOpen] = React.useState(false);
  const [workingHoursContent, setWorkingHoursContent] = React.useState();
  const [breakContent, setBreakContent] = React.useState();
  const [errMsg1, setErrMsg1] = React.useState("");
  const [errMsg2, setErrMsg2] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
   
    if( /\d\d:\d\d/.test(workingHoursContent) && /\d\d/.test(breakContent)) {
      setOpen(false);
      setErrMsg1("");
      setErrMsg2("");
      handleChangeOfWorkingHoursAndBreak(workingHoursContent, breakContent);
    }
    else {
      if(!/\d\d:\d\d/.test(workingHoursContent)) {
        setErrMsg1("Falsche Zeitangabe! Bitte im Format hh:mm eingeben.");
      }
      else { 
        setErrMsg1(""); 
      }

      if(!/\d\d/.test(breakContent)) {
          setErrMsg2("Falsche Zeitangabe! Bitte im Format mm eingeben.");
      }
      else { 
        setErrMsg2(""); 
      }
    } 
  };

  return (
    <div>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        ÄNDERN
      </Button>
      <Dialog open={open} onClose={handleClose} disableRestoreFocus>
        <DialogTitle color="primary.main">Arbeits- und Pausenzeit ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Geben sie hier ihre aktuelle tägliche Arbeitszeit ein:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="WorkingTime"
            label="Format hh:mm"
            type="search"
            fullWidth
            variant="filled"
            value={workingHoursContent}
            onChange={(e) => {
              setWorkingHoursContent(convertInputTime(e.target.value));
            }}
          />
          <DialogContentText color="error">
            {errMsg1}
          </DialogContentText>

          <DialogContentText marginTop="32px">
            Geben sie hier ihre tägliche Pausenzeit in Minuten ein:
          </DialogContentText>

          <TextField
            margin="dense"
            id="Break"
            label="Format mm"
            type="search"
            fullWidth
            variant="filled"
            value={breakContent}
            onChange={(e) => {
              setBreakContent(e.target.value);
            }}
          />
          <DialogContentText color="error">
            {errMsg2}
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSave}>Speichern</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import React from 'react';
import { useState, useEffect } from 'react';
import {Typography} from '@mui/material';

export default function Clock({sx}){
  const [date, setDate] = useState(new Date());
  
  function refreshClock() {
    setDate(new Date());
  }  

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return(
  <Typography variant="h4" component="span" sx={sx}>
    {date.toLocaleTimeString('de-DE')}
  </Typography>
  )
};
// convert different inputTimes to hh:mm

export default function convertInputTime(inputTime){
    let correctedTime = inputTime;
    let match;
   
    // convert inputTime  "930" ---> "09:30"
    if( (match = /^(\d)(\d)(\d)$/.exec(inputTime)) ) {
        if(match[1] !== '0') {
            correctedTime = "0" + match[1] + ":" + match[2] + match[3];
        } 
        
    }
     // convert inputTime  "0930" ---> "09:30"
    if( (match = /^(\d)(\d)(\d)(\d)$/.exec(inputTime)) ) {
        correctedTime = match[1] + match[2] + ":" + match[3] + match[4];
    }
    // convert inputTime  "9:30" ---> "09:30"
    if( (match = /^(\d):(\d)(\d)$/.exec(inputTime)) ) {
        correctedTime = "0" + match[1] + ":" + match[2]  + match[3];
    }

     // convert correctedTime(!) to "12:31" if converted to "01:231" previous
     if( (match = /^0(\d):(\d)(\d)(\d)$/.exec(correctedTime)) ) {
        correctedTime = match[1] + match[2] + ":" + match[3] + match[4];
    }
  
    console.log(inputTime + "--->" + correctedTime);
    
    return(correctedTime);
}

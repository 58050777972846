import Navbar from "./components/Navbar.js";
import TimeCard from "./components/TimeCard.js";
import ArrivalTimeDialog from "./components/DialogArrivalTime.js";

import { useState } from 'react';
import { StyledEngineProvider, Box } from '@mui/material';

import './App.css';

function App() {

  const [arrivalTime, setArrivalTime] = useState("08:15");

  function handleChangeOfArrivalTime(aTime) {
    setArrivalTime(aTime);
  }

  return (
    <StyledEngineProvider injectFirst>
      <div className="App">
        <Box
          sx={{
            width: 684,
            backgroundColor: '#eceff1',
            pb: 2,
          }}
        >
          <Navbar />

          <TimeCard Presence={{
            arrival: arrivalTime,
            workingHours: "08:15",
            break: "30"
          }} />

          <TimeCard Presence={{
            arrival: arrivalTime,
            workingHours: "06:00",
            break: "15"
          }} />

          <ArrivalTimeDialog
            handleChangeOfArrivalTime={handleChangeOfArrivalTime}
          />
        </Box>
      </div>
    </StyledEngineProvider>
  );
}

export default App;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function LeaveNowDialog() {    //ToDo: {handleLoggingWorkingTime} pass WorkingTime Data to App.js
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        setOpen(false);
        // handleLoggingWorkingTime(WorkingTimeObject); Call function to pass WorkingTime data to App.js to store in DB

    };

    return (
        <div>
            <Button size="small" variant="contained" color="success" onClick={handleClickOpen} >
                Jetzt!
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle color="primary">Jetzt "GEHEN"?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Dienstort jetzt verlassen und geleistete Arbeitszeit dokumentieren?
                    </DialogContentText>
                </DialogContent>   
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                        <Button  onClick={handleSave}>Jetzt speichern</Button>
                    </DialogActions>
            </Dialog>
        </div>
    );
}
import * as React from 'react';
import { useState, useEffect } from 'react';
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import WorkingHoursAndBreakDialog from "./DialogWorkingHoursAndBreak.js";
import LeaveNowDialog from './DialogLeaveNow';
import { Card, CardContent, CardActions, Stack, Typography, Divider } from '@mui/material';



export default function TimeCard({ Presence }) {

    var colorProgress;
    var colorTimeToWork;
    var textTimeToWork;
    var colorTimeStillWorked;
    var strTimeToWork;

    const [strWorkingHours, setStrWorkingHours] = useState(Presence.workingHours);
    const [strBreak, setStrBreak] = useState(Presence.break);

    function handleChangeOfWorkingHoursAndBreak(WorkingHours, Break) {
        setStrWorkingHours(WorkingHours);
        setStrBreak(Break);
    }

    //**************
    //  C L O C K
    //**************
    const [date, setDate] = useState(new Date());

    function refreshClock() {
        setDate(new Date());
    }

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000 * 60);  //every minute
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);
    //**** END Clock ****

    // converts hour, min in milliseconds
    function getMs(hour, min) {
        return ((hour * 60 * 60 * 1000) + (min * 60 * 1000));
    }

    // converts milliseconds in hour, min
    function getHourMin(ms) {
        const hour = Math.floor(ms / (60 * 60 * 1000));
        const min = (ms - (hour * 60 * 60 * 1000)) / (60 * 1000);
        return ([hour, min]);
    }



    //*** Calculating leaving time from arrival time and workingHours 
    //
    // get arrival Time (in hour/min)
    const [aHour, aMin] = Presence.arrival.split(":");

    // a for arrival time (in ms)
    const a = getMs(aHour, aMin);

    //*** calculate totalWorkingHours in ms (workingHours + break)
    // w for workingHours
    const [wHour, wMin] = strWorkingHours.split(":");
    const totalWorkingHours = getMs(wHour, wMin)
        + getMs(0, strBreak)  // break in min(!) => ms
    // l for leaving Time (l in ms)
    //calculate leaving time in milliseconds (arrival + totalWorkingHours)
    const l = a + totalWorkingHours
    const [lHour, lMin] = getHourMin(l);

    // c for current Time
    const cHour = date.getHours();
    const cMin = date.getMinutes();
    const c = getMs(cHour, cMin);

    //*** calculate timeToWork in ms
    const timeToWork = l - c;  // leaving time minus current time in ms
    const [tHour, tMin] = getHourMin(Math.abs(timeToWork));
    // convert to string for display
    strTimeToWork = tHour.toString().padStart(2, "0")
        + "h " + tMin.toString().padStart(2, "0")
        + "min";               // convert date object to string (e.g. "05h 10min")

    //*** calculate timeStillWorked in ms
    const timeStillWorked = c - a;  // current time minus arrival time
    // s for stillWorked
    const [sHour, sMin] = getHourMin(timeStillWorked);
    // convert to string for display
    const strTimeStillWorked = sHour.toString().padStart(2, "0")
        + "h " + sMin.toString().padStart(2, "0")
        + "min";               // convert date object to string (e.g. "05h 10min")

    //*** calculate time worked until now in percent
    const progress = 100 - (timeToWork / totalWorkingHours) * 100;

    // progress over 100% is overtime, then display the overtime with different colors
    if (progress <= 100) {
        colorProgress = "success";
        colorTimeStillWorked = "success.light";
        colorTimeToWork = "primary";
        textTimeToWork = "noch zu arbeiten";
    } else {
        colorProgress = "danger";
        colorTimeStillWorked = "error";
        colorTimeToWork = "error";
        textTimeToWork = "Überstunden";
    }

    // Save current WorkTimeData for storage in object
    const WorkTimeData = {              // ToDo: Arbeitszeitdaten über den Dialog LeaveNow übertragen an APP.JS (für DB)
        date: date,         
        arrival: Presence.arrival,
        WorkedTime: strTimeStillWorked,
    };

    return (

        <Card sx={{
            width: 650,
            m: 2
        }}>
            <CardContent>
                <Stack spacing={2} direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    <Stack>
                        <Typography variant="h4">{Presence.arrival}</Typography>
                        <Typography variant="h6">KOMMEN</Typography>
                    </Stack>

                    <Stack >
                        <Typography variant="subtitle2">{strWorkingHours}</Typography>
                        <Typography variant="subtitle2">(+{strBreak} min Pause)</Typography>
                        <CardActions>
                            <WorkingHoursAndBreakDialog
                                handleChangeOfWorkingHoursAndBreak={handleChangeOfWorkingHoursAndBreak}
                            />
                        </CardActions>
                    </Stack>

                    <Stack>
                        <Typography variant="h4">
                            {lHour.toString().padStart(2, "0")}:{lMin.toString().padStart(2, "0")}
                        </Typography>
                        <Typography variant="h6">GEHEN</Typography>

                        <LeaveNowDialog WorkTimeData={WorkTimeData}/>

                    </Stack>

                    <Stack>
                        <CircularProgressWithLabel value={progress} color={colorProgress} />
                    </Stack>

                    <Stack>
                        <Typography variant="subtitle2">
                            bereits gearbeitet
                        </Typography>
                        <Typography variant="subtitle2" color={colorTimeStillWorked}>
                            <strong >{strTimeStillWorked}</strong>
                        </Typography>
                        <Typography variant="subtitle2">
                            {textTimeToWork}
                        </Typography>
                        <Typography variant="subtitle2" color={colorTimeToWork}>
                            <strong >{strTimeToWork}</strong>
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>

    );
}

import React from "react";
import Clock from "./Clock.js";
import logo from "../img/Nindroborg.jpg";

import {useState, useEffect} from "react";

import {AppBar, Toolbar, Typography} from '@mui/material';
import {Avatar, IconButton, Box} from '@mui/material';


export default function Navbar() {
  const [date, setDate] = useState(new Date());
  const dateOptions = {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'};
  
  function refreshClock() {
    setDate(new Date());
  }  

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000 *60);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
            
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="logo"
                >
                    <Avatar
                    alt="Nindroborg"
                    sx={{ width: 40, height: 40 }}
                    src={logo}
                    />
                </IconButton>
                <Typography variant="h6" sx={{ flexGrow: 0 }}>
                    DIENSTZEIT
                </Typography>
                <Clock sx={{ flexGrow: 1 }}/>
                <Typography variant="h6" sx={{ flexGrow: 0 }}>
                    {date.toLocaleDateString("de-DE", dateOptions)}
                </Typography>
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}

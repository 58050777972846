import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import convertInputTime from '../utils/convertInputTime.js';

export default function ArrivalTimeDialog({ handleChangeOfArrivalTime }) {
  const [open, setOpen] = React.useState(false);
  const [inputContent, setInputContent] = React.useState();
  const [errMsg, setErrMsg] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if( /^\d\d:\d\d$/.test(inputContent) ) {
      setOpen(false);
      setErrMsg("");
      handleChangeOfArrivalTime(inputContent);
    }
    else{
      setErrMsg("Falsche Zeitangabe! Bitte im Format hh:mm eingeben.");
    }
  };

 
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} >
        "KOMMEN"-Zeit ändern...
      </Button>
      <Dialog open={open} onClose={handleClose} disableRestoreFocus>
        <DialogTitle color="primary.main">"KOMMEN"-Zeit ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Geben sie hier den aktuellen Beginn der Dienstzeit ein.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="ArrivalTime"
            label="Format hh:mm"
            type="search"
            fullWidth
            variant="filled"
            value={inputContent}
            onKeyUp={ (event) => {
              if (event.key === 'Enter'){
                handleSave();
              }
            }}
            onChange={(e) => {
              setInputContent(convertInputTime(e.target.value));
            }}
          />
          <DialogContentText color="error">
            {errMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSave}>Speichern</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}